
<script>
import {_} from 'vue-underscore';
import $ from 'jquery';
import { isMobile} from '@/plugin/isMobile.js';
import { getLocal} from '@/utils/local.js';
import moment from 'moment-timezone';
export default {
	name: "Notification",
	data() {
		return {
			data          : [],
			category      : [],
			current_page  :  1,
			last_page     :  1,
			per_page      :  10,
			total         :  0,
			page          :  1,
			currency      : getLocal('currency') || 'RM',
			img_url       : process.env.VUE_APP_IMAGE_URL,
		};
	},
	methods: {
		push_content(url,id)
		{
			this.$router.push(url+'?id='+id);
		},
		getdata(sw) {

			if(sw){
				this.data = [];
				this.page = 1;
				$('.notMore').css('display', 'none');
			}

			var data = {};
			var u = '/home/api/plan_ajax';

			this.$http("", u+"?page="+this.page, data,'post')
			.then((result) => {
				if(result.code == "0001"){
					console.log(result.data)
					this.data         = _.union(this.data,result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page    = result.data.last_page || 1;
					this.per_page     = result.data.per_page || 1;
					this.total        = result.data.total || 10;
					// this.data         = result.data.data;
						// console.log(this.data)
					if(_.size(result.data)==0){
						$('.notMore').css('display', 'block').html(this.$t('no_more'));
					}
				}

			});
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
		parseDate(date)
		{
			return moment(date).format('YYYY/MM/DD');
		},
	},
	beforeMount() {
		let token = getLocal('token') || '';
		if(!token)
			this.$router.push('/');
		// console.log(token)
		this.getdata(false);
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", function(){
			if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 1;
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					that.page++;
					that.getdata(false) ;
				}
			}
		});
	},
	unmounted() {
	}
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
html,body {
	height: 100%;
	margin: auto;
	overflow: initial;
	color:$black-100;
	font-size: 14px;
	background: $black-400;
}
.sub-mission-text2 {
	color: $gray-500!important;
	font-size: 14px;
	margin-top: auto;
}
.selected_cat {
	background: $orange!important;
	color: $white!important;
	padding-left: 5px;
	padding-right: 5px;
}
.outline-sd {
	box-shadow: 0px 0px 6px rgba($black-100, 0.161)!important;
	border-radius: 10px !important;
}
.movie-title {
	font-size: 17px;
	color: $black-300;
	font-weight: 700;
	padding-right: 5px;
	flex-shrink: 1;
}
.grade {
	font-weight: 700;
	color: $yellow-500;
	font-size: 15px;
}
.detail {
	line-height: 24px;
}
.line-ellipsis {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.ml-7 {
	margin-left: 7px;
}
.active_btm {
	width: 20px;
	border-bottom: 2px solid map-get($theme-colors, 'danger');
	margin-top: 5px;
}
.flex-box-center-column {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.flex-box-center {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
}
.rank-text {
	font-size: 40px;
	font-weight: bold;
	color: $gray-500;
}
p{color:$gray-500;}
.up-case {
	text-transform: uppercase;
}
</style>

<template>
  <base-layout3
	:page-title="$t('plan_order_details')"
	:show-back-button="true"
	:show-footer="false"
	mode=""
	style="padding-bottom:100px"
  >
	<div class="">

			<h3 class="section-title">
				<span>{{$t('plan_order_details' )}}</span>
			</h3>

			<div class="tab-pane fade show active" id="transfer" role="tabpanel" aria-labelledby="transfer-tab">
				<ul class="listing-log">
					<li  v-for="(item, k) in  data"  :key="k"  >
						<div class="title">
							<div>
								<h2 class="" style="color: #000;font-size: 12px;" >{{item.number}}</h2>
								<span>{{item.title}}</span>
							</div>
							<span v-if="item.status==0" class="text-warning">{{$t('plan_order_view_distribute') }}</span>
							<span v-if="item.status==1" class="text-info">{{$t('plan_order_view_done') }}</span>
						</div>
						<div>
							<span>
								<font-awesome-icon :icon="['far', 'calendar']" class="me-auto" />
								{{ parseDate(item.time)}}
							</span>
							<br/>
							<div style="float: right;">{{currency}} {{item.money}}</div>
						</div>
					</li>
				</ul>
				<div class="col-12 pb-5" style="display: block;text-align: center;width:100%;">
					<span class="notMore text-white">{{$t('drag_more') }}</span>
				</div>
			</div>

	</div>
  </base-layout3>
</template>