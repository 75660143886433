import { createRouter, createWebHashHistory } from "vue-router";
//import DefaultView from '../views/error/'
import { getLocal } from '@/utils/local.js';
import { isMobile } from '@/plugin/isMobile.js';
let routes = [];

/*  使用require.context实现前端工程化引入文件;
    require.context(directory, useSubdirectories = false, regExp = /^.//);
    directory: 要查找的文件路径,useSubdirectories: 是否查找子目录,regExp: 要匹配文件的正则;
*/

let theme = getLocal('theme') || "3";
let device = 'mobile';
let contexts = null;
window.theme = theme;
// console.log(theme)
// if(!isMobile() && theme=="2"){
//   device = 'desktop';
//   contexts = require.context("../views/desktop2", true, /\.vue$/);
// }

// if (isMobile() && theme == "2") {
//   contexts = require.context("../views/mobile2", true, /\.vue$/);
// }
/*
 if (isMobile() && theme == "3") {
    contexts = require.context("../views/mobile3", true, /\.vue$/);
}
*/
 contexts = require.context("../views/theme3", true, /\.vue$/);
// contexts = require.context("../views/mobile2", true, /\.vue$/);

contexts.keys().forEach((key) => {

  let fileUrl = key.replace(/\./, "");
  // 正则匹配掉".","/index"和"vue"
  let routePath = key.replace(/\.|\/index|vue/g, "") || "/";
  let routeName = routePath.replace(/\//, "").replace(/\//, "-") || "index";
  let obj = {
    path: routePath,
    name: routeName,
    component: () => import(`@/views/${process.env.VUE_APP_APP_CODE}${fileUrl}`),
  };
  // console.log(obj)
  routes.push(obj);
});

routes.push({
  path: "/:catchAll(.*)",
  redirect: '/',
  component: () => import(`@/views/${process.env.VUE_APP_APP_CODE}/index/index.vue`)
});

const router = createRouter({
  history: createWebHashHistory(),
  //history: createWebHistory(), //remove hash from URL
  routes,
  linkActiveClass: "active-link",
});
export default router;
