<template v-if="data.length != 0">
	<base-theme3
		:page-title="$t('recharge_index_recharge')"
		:show-logo="false"
		:showBackButton="true"
		mode="with-logo"

	>

		<div class="paydetails" :class="!isPayFrame ? 'd-none' : ''">
			<div style="position: absolute;top: 50%;right: 40vw;z-index:1">
				<div class="spinner-border" style="width: 5rem; height: 5rem;color:grey"></div>
			</div>
			<iframe :src="payurl" class="" ></iframe>
			<a @click="this.closeiframe()" class="close bg-dark">{{$t('trade_close')}}</a>
		</div>

		<div class="">
			<div class="main-container">
				<h3 class="section-title">{{ $t("recharge_payment_method") }}</h3>
				<div class="section-padding">
					<div class="btn-select d-flex flex-wrap">
						<button
								v-for="(item, k) in paymentgayway" :key="k"
							class="btn"
							:class="pay_method == item.code ? 'active' : ''"
							@click="pay_method = item.code;getbanks(item)"
						>
							{{banklang(item)}}
						</button>
					</div>
				</div>
				<div>
					<h3 class="reminder">{{ $t("recharge_note_3") }}</h3>
					<ul class="menu_listing" v-if="checkNull(paymentgayway.info)">
						<!-- bank transfer -->
						<li v-if="paymentgayway.code==0">
							<div>{{$t('account_info_account_name')}}</div>
							<div>
								<span id="b1" style="font-size: 12px;">
									<!-- <span v-if="(paymentinfo.credit2*1) >= 1000 ">{{data.config.setting.bank_account_name2}}</span>
									<span v-if="(paymentinfo.credit2*1) < 1000 ">{{data.config.setting.bank_account_name}}</span> -->
									<span>{{paymentgayway.info.bank_account_name || null}}</span>
								</span>
								<button class="btn btn-copy-small" @click="copyToClipboard('#b1')">
									{{$t('task_detail_copy' )}}
								</button>
							</div>
						</li>
						<li v-if="paymentgayway.code==0">
							<div>{{$t('recharge_payment_bank')}}</div>
							<div>
								<span id="b2" style="font-size: 12px;">
									<!-- <span v-if="(paymentinfo.credit2*1) >= 1000 ">{{data.config.setting.bank_account_branch2}}</span>
									<span v-if="(paymentinfo.credit2*1) < 1000 ">{{data.config.setting.bank_account_branch}}</span> -->
									<span>{{paymentgayway.info.bank_account_branch || null}}</span>
								</span>
								<button class="btn btn-copy-small" @click="copyToClipboard('#b2')">
									{{$t('task_detail_copy' )}}
								</button>
							</div>
						</li>
						<li v-if="paymentgayway.code==0">
							<div>{{$t('recharge_payment_bank_account_number')}}</div>
							<div>
								<span id="b3" style="font-size: 12px;">
									<!-- <span v-if="(paymentinfo.credit2*1) >= 1000 ">{{data.config.setting.bank_account_no2}}</span>
									<span v-if="(paymentinfo.credit2*1) < 1000 ">{{data.config.setting.bank_account_no}}</span> -->
									<span>{{paymentgayway.info.bank_account_no || null}}</span>
								</span>
								<button class="btn btn-copy-small" @click="copyToClipboard('#b3')">
									{{$t('task_detail_copy' )}}
								</button>
							</div>
						</li>


						<!-- ERC20 transfer -->
						<li v-if="paymentgayway.code==3 || paymentgayway.code==4">
							<img :src="imgurl+'/uploads/'+paymentgayway.thumb"  class="rounded  mx-auto d-block" />
						</li>
						<li v-if="paymentgayway.code==3 || paymentgayway.code==4">
							<div>{{$t('recharge_wallet_addr')}}</div>
							<div>
								<span id="b6" style="font-size: 12px;">
									<span style="display:none">{{paymentgayway.info.address || null}}</span>
								</span>
								<button class="btn btn-copy-small" @click="copyToClipboard('#b6')">
									{{$t('task_detail_copy' )}}
								</button>
							</div>
						</li>

						<li class="d-block" v-if="reupload">
							<input type="checkbox" value="1" v-model="check">
							<label class="terms" style="display: inline;">
								<a href="{{viewtype}}/terms.html" target="_blank" style="color: white !important;">{{$t('recharge_index_terms' )}}</a>
							</label>
						</li>
					</ul>
					<h3 class="reminder">{{ $t("recharge_note_4") }}</h3>
					<div class="section-padding">
						<!-- <div class="btn-select">
							<button
								value="100"
								class="btn"
								:class="recharge_amt.val == 100 ? 'active' : ''"
								@click="recharge_amt.val = 100"
							>
								{{ currency }}100
							</button>
							<button
								value="300"
								class="btn"
								:class="recharge_amt.val == 300 ? 'active' : ''"
								@click="recharge_amt.val = 300"
							>
								{{ currency }}300
							</button>
							<button
								value="600"
								class="btn"
								:class="recharge_amt.val == 600 ? 'active' : ''"
								@click="recharge_amt.val = 600"
							>
								{{ currency }}600
							</button>
							<button
								value="1000"
								class="btn"
								:class="recharge_amt.val == 1000 ? 'active' : ''"
								@click="recharge_amt.val = 1000"
							>
								{{ currency }}1000
							</button>
						</div> -->
						<div class="recharge_amount">
							<div class="currency">{{ $t("recharge_index_amount") }}</div>
							<div style="width:100%">
								<input type="number" style="font-size: 14px" class="form-control" v-model="recharge_amt.val" :placeholder="$t('recharge_amount_err')"  />
								<div v-if="!recharge_amt.isValid" class="error_message" >
									{{ $t("recharge_amount_err") }}
								</div>
							</div>
						</div>

						<div class="recharge_amount" v-if="isbanks">
							<div class="currency">{{ $t("recharge_payment_bank") }}</div>
							<div style="width:100%">
								<select class="form-control"  v-model="selectbank.val" >
									<option value="" >--{{$t('recharge_payment_bank')}}--</option>
									<option :value="item.bank_code" v-for="(item, k) in  banks"  :key="k" >{{item.name}}</option>
								</select>
								<div v-if="!selectbank.isValid" class="error_message" >
									{{ $t("recharge_payment_bank") }}
								</div>
							</div>
						</div>

						<div class="recharge_name" v-if="pay_method==521 || pay_method==522">
							<div class="name">{{ $t("recharge_index_name") }}</div>
							<div style="width:100%">
							<input
								type="text"
								style="font-size: 14px"
								name="realname"
								v-model="realname.val"
								class="form-control"
								:placeholder="$t('recharge_index_real_name')"
								@blur="clearValidity('realname')"
							/>

							<div v-if="!realname.isValid" class="error_message">
							{{ $t("recharge_index_real_name") }}
								</div>
							</div>
						</div>

						<div class="recharge_name" v-if="pay_method==3 || pay_method==4">
							<div class="name">{{ $t("recharge_txn_hash") }}</div>
							<div style="width:100%">
								<input
									type="text"
									style="font-size: 14px"
									name="txn_id"
									v-model="txn_id.val"
									class="form-control"
									:placeholder="$t('recharge_txn_hash')"
									@blur="clearValidity('txn_id')"
								/>

								<div v-if="!txn_id.isValid" class="error_message">
								{{ $t("recharge_txn_hash_err") }}
								</div>
							</div>
						</div>
						<div class="recharge_name" v-if="pay_method==='0'">
							<div class="name">{{ $t("recharge_index_ref_no") }}</div>
							<div style="width:100%">
								<input
									type="text"
									style="font-size: 14px"
									name="ref_no"
									v-model="ref_no.val"
									class="form-control"
									:placeholder="$t('recharge_ref_no_err')"
									@blur="clearValidity('ref_no')"
								/>

								<div v-if="!ref_no.isValid" class="error_message">
								{{ $t("recharge_ref_no_err") }}
								</div>
							</div>
						</div>

						<div class="termsandconditions">
							<input type="checkbox" value="1" v-model="check" checked />
							<a @click="redirect('/account/terms')">
								<label class="terms">
									{{ $t("recharge_index_terms") }}
								</label>
							</a>
						</div>

						<div class="bottomMessage">
							<p>{{ $t("minimum_amount").replace('_min_', this.min_recharge_fee) }}</p>
							<p>{{ $t("recharge_note_2") }}</p>
						</div>

						<div class="largebtncontainer" @click="submitForm()">
							<base-button mode="default-btn" type="submit" class="largeGoldButton">
								{{ $t("recharge_index_confirm_recharge") }}
							</base-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</base-theme3>
</template>

<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { getLocal } from "@/utils/local.js";
import { isMobile } from "@/plugin/isMobile.js";
import BaseTheme3 from '@/components/UI/themes/theme3/BaseTheme3.vue';
export default {
	name: "login",
	components: {
		BaseTheme3,
	},
	data() {
		return {
			lock: 0,
			pay_method: 0,
			data: {},
			paymentgayway: {},
			recharge: {},
			currency: getLocal("currency") || "RM",
			realname: {
				val: "",
				isValid: true,
			},
			recharge_amt: {
				val: "",
				isValid: true,
			},
			txn_id: {
				val: "",
				isValid: true,
			},
			ref_no: {
				val: "",
				isValid: true,
			},
			selectbank: {
				val: "",
				isValid: true,
			},
			recharge_online_banking: 0,
			current_page: 1,
			last_page: 1,
			per_page: 10,
			total: 0,
			page: 1,
			formIsValid: true,
			isPayFrame: false,
			payurl: null,
			banks: [],
			isbanks: 0,
			min_recharge_fee: 0,
			max_recharge_fee: 0
		};
	},
	created() {},
	updated() {},
	unmounted() {},
	beforeMount() {
		let token = getLocal("token") || "";
		this.token = token;
		if (!token) {
			this.$router.push("/login");
		} else {
			this._config();
			this._config_paymentgayway();
			// this.getlog();
		}
	},
	methods: {
		closeiframe() {
			this.isPayFrame = false;
		},
		clearValidity(input) {
			this[input].isValid = true;
		},
		checkNull(item) {
			if(_.isUndefined(item)  || item == null || _.size(item)==0 )
				return false;

			return true;
		},
		banklang(item){
			return _.isUndefined(item.info[this.$i18n.locale ])?item.title:item.info[this.$i18n.locale ];
		},
		getbanks(item){
			if(item.isBank==1){
				this.banks=item.bank;
				this.isbanks=item.isBank;
			}
			console.log(this.banks)
		},
		validateForm() {
			this.formIsValid = true;
			if((this.pay_method==521 || this.pay_method==522 )){
				if (this.realname.val === "") {
						this.realname.isValid = false;
						this.formIsValid = false;
				}
			}
			if((this.isbanks==1 )){
				if (this.selectbank.val === "") {
						this.selectbank.isValid = false;
						this.formIsValid = false;
				}
			}
			if (this.recharge_amt.val === "" || this.recharge_amt.val === 0) {
				this.recharge_amt.isValid = false;
				this.formIsValid = false;
			}
			if((this.pay_method==3 || this.pay_method==4 )){
				if (this.txn_id.val === "") {
					this.txn_id.isValid = false;
					this.formIsValid = false;
				}
			}
			if(this.pay_method==0){
				// if (this.ref_no.val === "") {
				// 	this.ref_no.isValid = false;
				// 	this.formIsValid = false;
				// }
			}
			if(_.isUndefined(this.pay_method)  || this.pay_method == null || _.size(this.pay_method)==0 ){
				this.formIsValid = false;
				this.$toast.error(this.$t('recharge_payment_method_err'));
			}
		},
		redirect(url) {
			this.$router.push(url);
		},
		recharge_redirect(item) {
			if (item.status != 1)
				this.$router.push("/account/recharge_payment?id=" + item.order_no);
		},
		_config_paymentgayway() {
			var that = this;
			this.$http("", "/home/api/paymentgatway", {one_only:0}, "post")
				.then((result) => {
					if (result.code == "0001") {
						this.paymentgayway = result.data;
						this.pay_method = this.paymentgayway.code;
						console.log(this.paymentgayway, 'paymentgayway')
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		submitForm() {
			var that = this;
			if (this.lock === 1) {
				return "";
			}

			this.lock = 1;
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}
			var data = {
				realname: this.realname.val,
				credit2: this.recharge_amt.val,
				pay_method: this.pay_method,
			};
			if (this.pay_method==0) data.extra = this.ref_no.val;
			else if (this.pay_method==3 || this.pay_method==4) data.extra = this.txn_id.val;

			if (that.isbanks==1)
				data['selectbank'] = that.selectbank.val;

			this.$http("", "/home/recharge/index", data, "post")
				.then((result) => {
					if (result.code == "0001") {
						this.$toast.success((result.message));
						var url = result.data || null;

						if (this.pay_method=='0'){
							setTimeout(function () {
								that.$router.push('/account/wallet');
							}, 1000);
						}
                        else if (this.pay_method=='axpay' || this.pay_method=='mpay1' || this.pay_method=='mpay2' | this.pay_method=='qepay3'){
                             this.lock = 0;
							if(!_.isUndefined(result.data) ){
                                window.open(url);
							}

						}
                        else{
							this.lock = 0;
							if(!_.isUndefined(result.data) ){
								this.isPayFrame=true;
								this.payurl = url;
							}

						}
					} else {
						this.lock = 0;
						this.$toast.error((result.message));
					}

					this.realname.val = "";
					this.recharge_amt.val = "";
					this.pay_method = "";
				})
				.catch((e) => {
					this.lock = 0;
					console.log(e);
				});
		},
		_config() {
			this.$http("", "/home/index/config", {}, "post")
				.then((result) => {
					if (result.code == "0001") {
						this.data = result.data;
						this.recharge_online_banking = this.data.config.setting.recharge_online_banking;
						this.min_recharge_fee = this.data.config.setting.min_recharge_fee;
						this.max_recharge_fee = this.data.config.setting.max_recharge_fee;
					}
				})
				.catch((e) => {
					console.log(e);
				});
		},
		getlog() {
			var per_page = 12;
			var data = {
				type: "recharge",
				per_page: per_page,
			};
			var that = this;
			var a = that.$t("no_more");
			this.$http(
				"",
				"/home/account/wallet_topup.html?page=" + this.page,
				data,
				"post"
			).then((result) => {
				if (result.code == "0001") {
					this.recharge = _.union(this.recharge, result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page = result.data.last_page || 1;
					this.per_page = result.data.per_page || 1;
					this.total = result.data.total || 10;
					this.data = result.data.data;
					// console.log(_.size(this.recharge))
					// console.log(this.recharge)
					if (_.size(result.data.data) == 0) {
						$(".notMore").css("display", "block").html(a);
					}
				}
			});
		},
		refreshlog(num) {
			this.page = num;
			var per_page = 12;
			var data = {
				type: "recharge",
				per_page: per_page,
			};
			this.recharge = {};
			this.$http(
				"",
				"/home/account/wallet_topup.html?page=" + this.page,
				data,
				"post"
			).then((result) => {
				if (result.code == "0001") {
					this.recharge = _.union(this.recharge, result.data.data);
					this.current_page = result.data.current_page || 1;
					this.last_page = result.data.last_page || 1;
					this.per_page = result.data.per_page || 1;
					this.total = result.data.total || 10;
					this.data = result.data.data;
					if (_.size(result.data.data) == 0) {
						$(".notMore").css("display", "block").html(this.$t("no_more"));
					}
				}
			});
		},
		copyToClipboard (element){
			var $temp = $("<input>");
			$("body").append($temp);
			$temp.val($(element).text()).select();
			document.execCommand("copy");
			$temp.remove();
			this.$toast.success(this.$t('recharge_copy_success'));

		},
	},
	mounted() {
		var that = this;
		window.addEventListener("scroll", function () {
			// console.log(234)
			if (!isMobile()) return;

			var v = $(document).height() - $(window).height() - 1;
			// console.log($(document).scrollTop(),v)
			if ($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if ($(".notMore").css("display") != "block") {
					that.page++;
					// that.getlog();
				}
			}
		});
	},
	watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container {
	padding-bottom: 0;
}
.btn-select button.active {
	border: 1px solid $brown-500;
	color: $yellow-500;
	background: linear-gradient(to left, map-get($theme-gradient-colors, 'secondary'));
}
.btn-select button {
	position: relative;
	text-align: center;
	border: 1px solid $gray-700;
	margin: 1%;
	width: 31%;
	padding: 10px 10px;
	color: $white;
	border-radius: 12px;
	font-weight: normal;
	background: linear-gradient(to left, map-get($theme-gradient-colors, 'secondary'));
}
.section-padding {
	padding: 20px 15px;
}
.recharge_amount {
	display: flex;
	flex-direction: column;
	font-size: 18px;
	color: $white;
	padding: 20px 0;
}
.recharge_amount .currency {
	width: 100%;
}
.recharge_amount input {
	width: 100%;
	/* max-width:450px; */
	margin-top: 10px;
	background: none;
	border: none;
	color: $yellow-500;
	box-shadow: 0 0 5px $yellow-500;
	font-size: inherit;
}
.recharge_amount select {
	width: 100%;
	/* max-width:450px; */
	margin-top: 10px;
	background: none;
	border: none;
	color: $yellow-500;
	box-shadow: 0 0 5px $yellow-500;
	font-size: inherit;
}
.recharge_name {
	display: flex;
	flex-direction: column;
	font-size: 18px;
	color: $white;
	padding: 20px 0;
}
.recharge_name .name {
	width: 100%;
}
.recharge_name input {
	width: 100%;
	margin-top: 10px;
	background: none;
	border: none;
	border-radius: 5px;
	color: $yellow-500;
	box-shadow: 0 0 5px $yellow-500;
}
.termsandconditions {
	padding: 10px 0 20px;
}
label {
	display: inline-block;
}
.bottomMessage {
	padding: 0px 0 10px 0;
	color: $yellow-500;
}
.listing-log .recharge .status-reject {
	color: $pink;
}
.listing-log li {
	display: flex;
	color: $white;
	background: rgba($black-100, 0.6);
	border-bottom: dashed 1px $black-300;
	padding: 18px 30px;
	justify-content: space-between;
}
.listing-log .title {
	display: flex;
}
.listing-log .recharge {
	display: flex;
}
.listing-log .more {
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: right;
}
ul.menu_listing {
	background: rgba($black-100, 0.376);
}
ul.menu_listing .menu_item {
	display: flex;
	align-items: center;
	justify-content: center;
}
ul.menu_listing li {
	display: flex;
	color: $white;
	border-bottom: solid 1px $black-300;
	padding: 18px 13px;
	justify-content: space-between;
	align-items: center;
	background: $black-100;
	border-radius: 10px;
	margin: 10px;
	padding: 10px 10px;
}
ul.menu_listing li:first-child {
	border-left: 10px solid $blue;
}
ul.menu_listing li:nth-child(2) {
	border-left: 10px solid $teal;
}
ul.menu_listing li:nth-child(3) {
	border-left: 10px solid $blue-dark;
}
ul.menu_listing li:nth-child(4) {
	border-left: 10px solid $yellow-500;
}
.btn-copy-small {
	color: map-get($theme-colors, 'danger');
	border: 1px solid map-get($theme-colors, 'danger');
	margin-left: 5px;
	padding: 0.2rem 0.3rem;
}
.error_message{
	margin: 5px auto 0 0;
	font-size: 12px;
	color: map-get($theme-colors, 'danger');
}

.paydetails {
	background: $black-400;
    // height: 100%;
	height: calc(100vh - 64px);
    position: fixed;
    width: 100%;
    // top: 54px;
    top: 0;
	left:0;
    z-index: 1000;

	a.close {
		position: fixed;
		color: $white;
		text-decoration: none;
		font-size: 16px;
		text-align: center;
		width: 100%;
		background: rgba($black-100, 0.376);
		bottom: 0;
		padding: 20px;
		z-index: 20;
	}
}

iframe {
    display: block;       /* iframes are inline by default */
    border: none;         /* Reset default border */
    height: calc(100vh - 54px);        /* Viewport-relative units */
    width: 100vw;
	z-index: 10;
	position: relative;
}

</style>
