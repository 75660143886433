<template v-if="data.length != 0">
  <base-theme3
	:page-title="$t('account_my_wallet')"
	:show-logo="false"
	:showBackButton="true"
	mode="with-logo"
  >

  <div class="account container px-2 py-5" style="position: relative">
	  <div class="row">		
		  <div class="col-12 text-center">
			  
			<div class="small ng-binding"><van-icon name="paid"/> {{$t('account_index_available_balance')}} </div>
			<div class="bold ng-binding" style="font-size: 35px; line-height:35px;">{{currency}} {{balance}}</div>
			<!-- <span>{{ $t("account_balance") }}: {{ currency }}{{ this.balance }}</span> -->
		  </div>
		</div>
	</div>

	<div class="">
	  <div class="main-container">
		<div>
			<!-- <h3 class="section-title">
			{{ $t("wallet_recharge_log") }}
			<span class="iconRefresh" @click="refreshlog(1)">
				<font-awesome-icon :icon="['fas', 'sync-alt']" class="me-auto" />
			</span>
			</h3> -->

			<div class="container">
				<div class="row text-white">
					<div class="col-6  flex-box-center-column position-relative px-2" style="font-size: 18px;"  @click='getlog("recharge",true);'>
						<div :class="type=='recharge' ?'active_btm':''">{{$t('wallet_recharge_log') }}</div>
					</div>
					<div class="col-6 flex-box-center-column bold position-relative px-2" style="font-size: 18px;" @click='getlog("withdraw",true);'>
						<div :class="type=='withdraw' ?'active_btm':''">{{$t('wallet_withdraw_log') }}</div>
					</div>
					<div class="col-12 me-bar2" style=""></div>
				</div>
			</div>

			<ul class="listing-log">
			<li
				v-for="(item, k) in recharge"
				:key="k"
				@click="recharge_redirect(item)"
			>
				<div class="title">
				<div>
					<h2>{{ item.id }} {{ item.order_no }}</h2>
					<span
					><font-awesome-icon
						:icon="['far', 'calendar']"
						class="me-auto"
					/>
					{{ item.create_time }}</span
					>
				</div>
				</div>
				<div class="recharge">
				<div v-if="item.status == 1">
					<span class="status status-complete text-success"
					><font-awesome-icon
						:icon="['fas', 'check']"
						class="me-auto"
					/>
					{{ $t("wallet_user_cash_success") }}
					</span>
					<br />
					<span class="text-success"
					><sup>{{ currency }}</sup
					>{{ item.credit2 }}</span
					>
				</div>
				<div v-if="item.status == -1">
					<span class="status status-reject"
					><font-awesome-icon
						:icon="['fas', 'check']"
						class="me-auto"
					/>
					{{ $t("wallet_user_cash_fail") }}
					</span>
					<br />
					<span style="color: #fc445f"
					><sup>{{ currency }}</sup
					>{{ item.credit2 }}</span
					>
				</div>
				<div v-if="item.status == 0">
					<span class="status" style="color: #fc7940"
					><font-awesome-icon
						:icon="['fas', 'clock']"
						class="me-auto"

					/>
						<span style="color: #fc7940;margin-left:3px;" v-if="item.pay_time > 0">
					{{ $t("wallet_user_withdraw_pending") }}
						</span>
						<span style="color: #fc7940;margin-left:3px;" v-if="item.pay_time == null || item.pay_time <= 0">
					{{ $t("wallet_user_cash_pending") }}
						</span>
					</span>
					<br />
					<span style="color: #fc7940"
					><sup>{{ currency }}</sup
					>{{ item.credit2 }}</span
					>
				</div>



				<div class="more" v-if="item.status==0 && (item.pay_time == null || item.pay_time <= 0) && type=='recharge'">
					<font-awesome-icon
					:icon="['fas', 'chevron-right']"
					class="me-auto ml-5"
					/>
				</div>
				</div>
			</li>
			</ul>

		  <div
			class="col-12 pb-5"
			style="display: block; text-align: center; width: 100%"
		  >
			<span class="notMore text-white">{{ $t("drag_more") }}</span>
		  </div>
		</div>
	  </div>
	</div>
  </base-theme3>
</template>

<script>
import { _ } from "vue-underscore";
import $ from "jquery";
import { getLocal } from "@/utils/local.js";
import { isMobile } from "@/plugin/isMobile.js";
import BaseTheme3 from '@/components/UI/themes/theme3/BaseTheme3.vue';
export default {
  name: "login",
  components: {
    BaseTheme3,
  },
  data() {
	return {
	  lock: 0,
	  pay_method: 0,
	  data: {},
	  recharge: {},
	  balance: 0,
	  type: 'recharge',
	  currency: getLocal("currency") || "RM",
	  realname: {
		val: "",
		isValid: true,
	  },
	  recharge_amt: {
		val: 100,
		isValid: true,
	  },
	  recharge_online_banking: 0,
	  current_page: 1,
	  last_page: 1,
	  per_page: 10,
	  total: 0,
	  page: 1,
	  formIsValid: true,
	};
  },
  created() {},
  updated() {},
  unmounted() {},
  beforeMount() {
	let token = getLocal("token") || "";
	this.token = token;
	if (!token) {
	  this.$router.push("/login");
	} else {
	  this.refreshBalance();
	  this.getlog('recharge',false);
	}
  },
  methods: {
	redirect(url) {
	  this.$router.push(url);
	},
	recharge_redirect(item) {
	  if (item.status != 1 && (item.pay_time == null || item.pay_time <= 0  ))
		this.$router.push("/account/recharge_payment?id=" + item.order_no);
	},
	refreshBalance() {
	  this.balance = 0;
	  this.$http("", "/home/account/basic", [], "post")
		.then((result) => {
		  // console.log(result)
		  if (result.code == "0001") {
			this.balance = result.data.credit2 || null;

			// console.log(result.data)
		  } else {
			// this.$toast.error(result.message);
		  }
		})
		.catch((e) => {
		  console.log(e);
		  this.lock = 0;
		  //this.$toast.error(e.message);
		});
	},
	getlog(type,sw) {
	  this.type=type;
	  var per_page = 12;
	  var data = {
		type: this.type,
		per_page: per_page,
	  };
	  var that = this;
	  var a = that.$t("no_more");

		if(sw){
			this.recharge = [];
			this.page = 1;
			// $('.notMore').css('display', 'none');
		}

	  this.$http(
		"",
		"/home/account/wallet_topup.html?page=" + this.page,
		data,
		"post"
	  ).then((result) => {
		if (result.code == "0001") {
		  this.recharge = _.union(this.recharge, result.data.data);
		  this.current_page = result.data.current_page || 1;
		  this.last_page = result.data.last_page || 1;
		  this.per_page = result.data.per_page || 1;
		  this.total = result.data.total || 10;
		  this.data = result.data.data;
		  // console.log(_.size(this.recharge))
		  // console.log(this.recharge)
		  if (_.size(result.data.data) == 0) {
			$(".notMore").css("display", "block").html(a);
		  }
		}
	  });
	},
	refreshlog(num) {
	  this.page = num;
	  var per_page = 12;
	  var data = {
		type: "recharge",
		per_page: per_page,
	  };
	  this.recharge = {};
	  this.$http(
		"",
		"/home/account/wallet_topup.html?page=" + this.page,
		data,
		"post"
	  ).then((result) => {
		if (result.code == "0001") {
		  this.recharge = _.union(this.recharge, result.data.data);
		  this.current_page = result.data.current_page || 1;
		  this.last_page = result.data.last_page || 1;
		  this.per_page = result.data.per_page || 1;
		  this.total = result.data.total || 10;
		  this.data = result.data.data;
		  if (_.size(result.data.data) == 0) {
			$(".notMore").css("display", "block").html(this.$t("no_more"));
		  }
		}
	  });
	},
    scroll() {
      if(!isMobile())
				return;

			var v = $(document).height() - $(window).height() - 100;
      // console.log($(document).scrollTop(), v)
			if($(document).scrollTop() >= v) {
				// console.log('我到底了');
				if($('.notMore').css('display') != "block") {
					this.page++;
					this.getlog(this.type,false);
				}
			}
    }
  },
  mounted() {
	var that = this;
	window.addEventListener("scroll", this.scroll);
  },
  watch: {
	$route(to, from) {
		window.removeEventListener('scroll', this.scroll);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container {
  padding-bottom: 0;
}
.btn-select button.active {
  border: 1px solid $yellow-500;
  color: $yellow-500;
}
.btn-select button {
  position: relative;
  text-align: center;
  border: 1px solid $white;
  margin: 1%;
  width: 31%;
  padding: 10px 10px;
  color: $white;
  border-radius: 12px;
  font-weight: bold;
}
.section-padding {
  padding: 20px 15px;
}
.recharge_amount {
  display: flex;
  font-size: 24px;
  color: $white;
  padding: 20px 0 0;
}
.recharge_amount .currency {
  width: 10%;
}
.recharge_amount input {
  width: 90%;
  margin-left: 20px;
  background: none;
  border: none;
  color: $yellow-500;
}
.recharge_name {
  display: flex;
  font-size: 18px;
  color: $white;
  padding: 20px 0;
}
.recharge_name .name {
  width: 20%;
}
.recharge_name input {
  width: 80%;
  margin-left: 20px;
  background: none;
  border: none;
  border-radius: 5px;
  color: $yellow-500;
  box-shadow: 0 0 5px $yellow-500;
}
.termsandconditions {
  padding: 10px 0 20px;
}
label {
  display: inline-block;
}
.bottomMessage {
  padding: 40px 0;
  color: $yellow-500;
}
.listing-log .recharge .status-reject {
  color: map-get($theme-colors, 'danger');
}
.listing-log li {
  display: flex;
  color: $white;
  background: rgba($black-100, 0.6);
  border-bottom: dashed 1px $black-300;
  padding: 15px;
  justify-content: space-between;
}
.listing-log .title {
  display: flex;
}
.listing-log .recharge {
  display: flex;
}
.listing-log .more {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: right;
}

.active_btm {
	background: -webkit-linear-gradient(map-get($theme-gradient-colors, 'primary'));
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	position: relative;
}
.active_btm:after{
		content: '';
		position: absolute;
		height: 2px;
		width: 100%;
		border-radius: 30px;
		left: 0;
		right: 0;
		bottom: -5px;
		margin: 0 auto;
		transition: all .3s ease-in-out;
		background-color: $yellow-500;
		background-image: linear-gradient(to bottom, map-get($theme-gradient-colors, 'primary'));
}
.flex-box-center-column {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
}
.account {
	background: url(~@/assets/images/themes/theme3/yellow-profile-bg.png) no-repeat;
	background-size:cover;
	background-position:center center;
	.container {
		.row {
			.col-6 {
				span {
					font-size: 14px;
					white-space: nowrap;
				}
			}
		}
	}
	i {
		font-size:20px;
	}
}
</style>
