<template>
  <base-layout3
    :page-title="$t('account_bank_info')"
    :show-logo="false"
    :showBackButton="true"
    mode="with-logo"
  >
    <div class="container pt-4">
      <div class="main-container">
        <h3 class="section-title">{{ $t('account_info_bank_card_info') }}</h3>

        <form @submit.prevent="submitForm">
          <ul class="menu_listing">
            <li>
              <div class="menu_item">
                {{ $t('account_withdraw_real_name') }}
              </div>
              <div class="col-7">
                <input
                  type="text"
                  class="form-control b-none"
                  :placeholder="$t('account_withdraw_real_name')"
                  v-model="bank_account_name.val"
                  @blur="clearValidity('bank_account_name')"
                />
                <div
                  v-if="!bank_account_name.isValid"
                  class="error_message p10"
                >
                  {{ $t('account_withdraw_real_name') }}
                </div>
              </div>
            </li>
            <li>
              <div class="menu_item">{{ $t('system_bank_card1') }}</div>
              <div class="col-7">
                <input
                  type="text"
                  class="form-control b-none"
                  :placeholder="$t('system_bank_card')"
                  v-model="bank_account.val"
                  @blur="clearValidity('bank_account')"
                />
                <div v-if="!bank_account.isValid" class="error_message p10">
                  {{ $t('system_bank_card') }}
                </div>
              </div>
            </li>
            <li>
              <div class="menu_item">{{ $t('bank_branch_err') }}</div>
              <div class="col-7">
                <input
                  type="text"
                  class="form-control b-none"
                  :placeholder="$t('system_bank_branch')"
                  v-model="bank_branch.val"
                  @blur="clearValidity('bank_branch')"
                />
                <div v-if="!bank_branch.isValid" class="error_message p10">
                  {{ $t('system_bank_branch') }}
                </div>
              </div>
            </li>
            <li>
              <div class="menu_item">{{ $t('login_label_phone') }}</div>
              <div class="col-7">
                <input
                  type="tk"
                  class="form-control b-none"
                  :placeholder="$t('login_label_phone')"
                  v-model="contact_number.val"
                  @blur="clearValidity('contact_number')"
                />
                <div v-if="!contact_number.isValid" class="error_message p10">
                  {{ $t('login_label_phone') }}
                </div>
              </div>
            </li>
          </ul>

          <div class="largeButtonContainer">
            <base-button
              mode="default-btn"
              type="submit"
              class="largeGoldButton opacity-unset mt-3"
              @click="submitForm()"
              >{{ $t('account_review_submit') }}</base-button
            >
          </div>
        </form>
      </div>
    </div>
  </base-layout3>
</template>

<script>
export default {
  name: 'login',
  components: {},
  data() {
    return {
      lock: 0,
      data: {},
      bank_account_name: {
        val: '',
        isValid: true,
      },
      bank_account: {
        val: '',
        isValid: true,
      },
      bank_branch: {
        val: '',
        isValid: true,
      },
      contact_number: {
        val: '',
        isValid: true,
      },
      formIsValid: true,
    };
  },
  mounted() {},
  created() {},
  updated() {},
  unmounted() {},
  beforeMount() {
    this._config();
  },
  methods: {
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      if (this.bank_account_name.val === '') {
        this.bank_account_name.isValid = false;
        this.formIsValid = false;
      }
      if (this.bank_account.val === '') {
        this.bank_account.isValid = false;
        this.formIsValid = false;
      }
      if (this.bank_branch.val === '') {
        this.bank_branch.isValid = false;
        this.formIsValid = false;
      }
    },
    submitForm() {
      if (this.lock === 1) {
        return '';
      }
      this.lock = 1;
      this.validateForm();

      if (!this.formIsValid) {
        this.lock = 0;
        return;
      }

      let obj = {
        bank_account_name: this.bank_account_name.val,
        bank_account: this.bank_account.val,
        bank_branch: this.bank_branch.val,
        contact_number: this.contact_number.val,
      };
      // return console.log(1)

      this.$http('', '/home/account/bank.html', obj, 'post')
        .then((result) => {
          if (result.code == '0001') {
            this.$toast.success(result.message);
			setTimeout(
              () => this.$router.push('/account'),
              parseInt(result.time) * 1000
            );
          } else {
            this.$toast.error(result.message);
            this.lock = 0;
          }
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
        });
    },
    _config() {
      this.$http('', '/home/account/withdraw_config.html', {}, 'post')
        .then((result) => {
          if (result.code == '0001') {
            this.data = result.data;

            if (this.data.member.is_bind == 0) {
              // this.$toast.error(this.$t('account_withdraw_verify_first'));
              // setTimeout(function(){
              // 	this.redirect("/review?f=true");
              // }, 2000);
            }
            console.log(this.data.member.bank_branch);
            if (this.data.member.bank_branch != null)
              this.bank_branch.val = this.data.member.bank_branch;

            if (this.data.member.bank_account_name != null)
              this.bank_account_name.val = this.data.member.bank_account_name;

            if (this.data.member.bank_account != null)
              this.bank_account.val = this.data.member.bank_account;

            if (this.data.member.contact_number != null)
              this.contact_number.val = this.data.member.contact_number;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
ul.menu_listing .menu_item {
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.menu_listing li {
  display: flex;
  color: $white;
  border-bottom: solid 1px $black-300;
  padding: 18px 0;
  justify-content: space-between;
}
ul.menu_listing input {
  color: $yellow-100;
  box-shadow: 0 0 5px $yellow-500;
}
.b-none {
  border: none !important;
}
.form-control,
.form-control:focus {
  color: $white;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $black-600;
  border-radius: 0;
  padding: 10px 10px 10px 10px;
  box-shadow: none;
}
.p10 {
  margin: 0 0 0 10px;
}
.error_message {
  color: map-get($theme-colors, 'danger');
  font-size: 12px;
  margin: 5px auto 0 0;
}
</style>
