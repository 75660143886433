<template>
	<footer>
		<div class="container">
			<ul class="nav nav-pills nav-justified">
				<li class="nav-item">
					<a class="nav-link"  @click="redirect('/')"  :class="active_footer('home')">
						<span class="nav-icon icon-home"></span>
						<span class="nav-text">{{$t("footer_home")}}</span>
					</a>
				</li>
				<!-- <li class="nav-item">
					<a class="nav-link" @click="redirect('/movie')" :class="active_footer('movie')" >
						<span class="nav-icon icon-movie"></span>
						<span class="nav-text">{{$t("footer_movie")}}</span>
					</a>
				</li>
        	    <li class="nav-item">
					<a class="nav-link" @click="redirect('/movie/ticket')" :class="active_footer('ticket')">
						<span class="nav-icon icon-ticket"></span>
						<span class="nav-text">{{$t("footer_ticket")}}</span>
					</a>
				</li> -->
        <li class="nav-item">
					<a class="nav-link" @click="redirect('/trade/orderdetails')" :class="active_footer('orderdetails')" >
						<span class="nav-icon icon-deposit"></span>
						<span class="nav-text">{{$t("trade_order_list")}}</span>
					</a>
				</li>
        <!-- <li class="nav-item">
					<a class="nav-link" @click="redirect('/account/deposit')" :class="active_footer('deposit')" >
						<span class="nav-icon icon-deposit"></span>
						<span class="nav-text">{{$t("index_recharge")}}</span>
					</a>
				</li> -->
        <!-- <li class="nav-item">
					<a class="nav-link" @click="gosupport()">
						<span class="nav-icon icon-support"></span>
						<span class="nav-text">{{$t("login_livechat")}}</span>
					</a>
				</li> -->
        <!-- <li class="nav-item">
					<a class="nav-link" @click="redirect('/trade/orderdetails')" :class="active_footer('orderdetails')" >
						<span class="nav-icon icon-orderlist"></span>
						<span class="nav-text">{{$t("footer_orderlist")}}</span>
					</a>
				</li> -->
				<li class="nav-item">
					<a class="nav-link" @click="redirect('/plan/plan')" :class="active_footer('plan')">
						<span class="nav-icon icon-rank"></span>
						<span class="nav-text">{{$t("index_rank")}}</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link"  @click="redirect('/account')" :class="active_footer('account')">
						<span class="nav-icon icon-personal"></span>
						<span class="nav-text">{{$t("footer_me")}}</span>
					</a>
				</li>
			</ul>
		</div>
	</footer>
    <!-- <div class="livechat-iframe" :class="isiframe == true ? '' : 'd-none'" >
			<iframe :src="service_link" class="" ></iframe>
			<a v-if="isiframe" @click="this.closeiframe()" class="close2"> 《 </a>
		</div> -->
</template>

<script>
import { getLocal} from '@/utils/local.js';
export default {
  data() {
    return {
			isiframe: false,
			issupport: false,
			service_link: "",
    }
  },
  methods: {
    // 页面跳转
    skipPage(path, query) {
      this.$router.push({ path, query });
    },
    active_footer(page) {
      var path = this.$route.name;
			if(page=='home'){
				if(path=='index')
					return 'active';
			}else if(page=="personal_invite" && path.includes("trade-orderdetails")){
				return 'active';
			}else if(page=="deposit" && path.includes("account-deposit")){
				return 'active';
			}else if(page=="plan" && path.includes("plan")){
				return 'active';
			}else if(page=="index" && path.includes("market")){
				return 'active';
			}else{
				if(path.includes(page) && !path.includes("account-deposit"))
					return 'active';
			}
			return "";
		},
    redirect(url) {
         this.$router.push(url);
    },
    // 跳转后台编辑条款协议页面
    skipClause(index) {
      this.$router.push({
        name: "article-clause",
        query: {
          genre: index,
        },
      });
    },
    closeiframe() {
			this.isiframe = false;
			this.issupport = false;
      this.service_link = '-';
			document.querySelector('html').removeAttribute("style");
      document.querySelector('body').removeAttribute("style");
		},
		gosupport() {
      this.service_link = getLocal("service_link") || "-",
			this.isiframe = true;
			this.issupport = true;
      document.querySelector('html').setAttribute("style", "max-width: unset;");
      document.querySelector('body').setAttribute("style", "max-width: unset;");
		},
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9!important;
    font-size:12px;
    background-color: $black-200;
    box-shadow: 0px -2px 5px rgb($black-100,10%);
    -moz-box-shadow: 0px -2px 5px rgba($black-100, 0.1);
    -webkt-box-shadow: 0px -2px 5px rgba($black-100, 0.1);
	transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
	-moz-backdrop-filter: saturate(120%) blur(15px);
    -webkit-backdrop-filter: saturate(120%) blur(15px);
    backdrop-filter: saturate(120%) blur(15px);
}
footer .nav-icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 0 auto;
    background-size: contain;
    opacity: 0.5;
    background-position: center;
    background-repeat: no-repeat;
}
.icon-home {
  background-image: url("~@/assets/images/3/icon-home.png");
}

.icon-movie {
  background-image: url("~@/assets/images/mobile/icon-movie.png");
}
.icon-rank {
  background-image: url("~@/assets/images/3/icon-ranking.png");
}
.icon-ticket {
  background-image: url("~@/assets/images/mobile/icon-ticket.png");
}
.icon-deposit {
  background-image: url("~@/assets/images/3/deposit.png");
}
.icon-personal {
  background-image: url("~@/assets/images/3/icon-profile.png");
}
.icon-orderlist {
    background-image: url("~@/assets/images/3/icon-oderlist.png");
}
.icon-market {
    background-image: url("~@/assets/images/3/icon-oderlist.png");
}
.icon-support {
  background-image: url("~@/assets/images/3/support.png");
}

footer .nav {
    align-items: center;
    max-width: 480px;
    margin: 0 auto;
}
footer .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
footer .nav .nav-item {

}
footer .nav-justified > .nav-link, .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}
footer .nav-justified .nav-item, .nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
    color: $white;
}
footer .nav li.nav-item a.nav-link:hover {
    color: $yellow-300;
}
footer .nav .nav-item .nav-link span {
    opacity: 0.5;
    color: $yellow-300;
}
footer .nav .nav-item .nav-link.active {
    color: $yellow-300;
    span {
        opacity: 1;
    }
    .icon-home {
        opacity: 1.0;
    }
    .icon-personal {
        opacity: 1.0;
    }
}
footer .nav .nav-item .nav-link {
    text-align: center;
    background: transparent;
    align-self: center;
    -webkit-align-self: center;
    -moz-align-self: center;
    height: 100%;
    position: relative;
}
footer .nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
    width: 100%;
}
footer .nav-link {
    display: block;
    padding: 5px 0px 3px;
    color: $white;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
footer .nav .nav-item .nav-link span .nav-text {
    font-size: 12px;
    line-height: 18px;
    vertical-align: middle;
}
footer .nav .nav-item .nav-link span {
  line-height: 13px;
    font-size: 10px;
    display: block;
    vertical-align: middle;
}

.close2 {
    background:$blue;
    position: fixed;
    color: $white;
    width: 35px;
    height: 35px;
    top : 20px;
    left: 0px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}
.livechat-iframe {
	background: $black-400;
    // height: 100%;
	  height: calc(100vh - 64px);
    position: fixed;
    width: 100%;
    // top: 54px;
    top: 0;
    left:0;
    z-index: 1000;

	a.close {
		position: fixed;
		color: $white;
		text-decoration: none;
		font-size: 16px;
		text-align: center;
		width: 100%;
		background: rgba($black-100, 0.376);
		bottom: 0;
		padding: 20px;
	}
}

iframe {
    display: block;       /* iframes are inline by default */
    border: none;         /* Reset default border */
    height: 100vh;      /* Viewport-relative units */
    width: 100vw;
}
</style>

