<script>
import { mapState } from 'vuex';
import { set, removeLocal, getLocal } from '@/utils/local.js';
import $ from 'jquery';
import FooterTheme3 from '@/components/layouts/FooterTheme3.vue';
import Market from '@/components/Market.vue';
// import '@/assets/css/style3.css';

export default {
  data() {
    return {
      view: {
        topOfPage: true,
      },
      lang: [
        {
          name: '简体中文',
          abbreviate: 'cn',
        },
        {
          name: 'English',
          abbreviate: 'en',
        },
      ],
      counter: 0,
      ann_title: null,
      ann_detail: null,
      dialogIsVisible: false,
      logo: '',
      sidenavWidth: 0,
      burger_btn: process.env.VUE_APP_BURGER_BTN,
    };
  },
  components: {
    FooterTheme3,
    Market,
  },
  mounted() {
    if (this.token != null && typeof this.token != 'undefined') {
      // this.getNotification();
    }
  },
  beforeMount() {
    this.updateMap();
    if (
      !this.$route.path.includes('/login') &&
      !this.$route.path.includes('/register')
    )
      this.getUnread();
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
  },

  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    showBurgerButton: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
    showHomeButton: {
      type: Boolean,
      default: false,
    },
    showLangButton: {
      type: Boolean,
      default: false,
    },
    showReloadButton: {
      type: Boolean,
      default: false,
    },
    showItem: {
      type: Boolean,
      default: true,
    },
    showNav: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: '',
    },
    pageDefaultBackLink: {
      type: String,
      default: '',
    },
    showBurgerPage: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
		parentFunction(id ) {
			this.$emit("return-product",id);
		},
    test() {
      alert('2');
    },
    handleScroll() {
      if (window.pageYOffset > 0) {
        if (this.view.topOfPage) this.view.topOfPage = false;
      } else {
        if (!this.view.topOfPage) this.view.topOfPage = true;
      }
    },
    switchLang(val) {
      if (val && this.$i18n.locale != val) {
        this.$i18n.locale = val;
        localStorage.setItem('lang', val);
        //this.$router.go(0);
      }
    },
    goPrevPage() {
      if (this.pageDefaultBackLink == '') {
        this.$router.go(-1);
      } else {
        this.$router.push(this.pageDefaultBackLink);
      }
    },
    updateMap() {
      this.$http('', '/home/index/config', [], 'get')
        .then((result) => {
          // console.log(result)
          if (result.code == '0001') {
            set('config', null);
            this.config = result.data.config.setting;
            var logo =
              process.env.VUE_APP_IMAGE_URL + '/uploads/' + this.config.logo;
            this.logo = logo;
            var currency = this.config.currency_symbol || 'RM';
            var appurl = this.config.appurl || '-';
            var service_link = this.config.service_link || '-';
            // console.log(currency)
            set('config', JSON.stringify(this.config));
            set('currency', currency);
            set('appurl', appurl);
            set('service_link', service_link);

            $('title').html(this.config.title + '-' + this.pageTitle);
            // console.log(logo)
          } else {
            // this.$toast.error(result.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
          //this.$toast.error(e.message);
        });
    },
    getUnread() {
      this.$http('', '/home/inbox/unread', [], 'post')
        .then((result) => {
          if (
            typeof result !== 'undefined' &&
            typeof result.code !== 'undefined' &&
            result.code == '0001'
          ) {
            this.counter = result.data || 0;
            // console.log(this.counter)
          } else {
            if (window.location.pathname != '/') {
              const tips = {
                en: 'Please login to continue operation',
                cn: '请登录后再操作',
                my: 'Sila log masuk dan kemudian ambil tindakan',
              };

              removeLocal('token');
              let lang = getLocal('lang') || 'cn';
              this.$toast.error(tips[lang]);
              setTimeout(() => {
                this.$store.commit('deleteSession');
                this.$router.push('/login');
              }, 500);
              // this.$toast.error(result.message);
            }
          }
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
          //this.$toast.error(e.message);
        });
    },
    hideDialog() {
      this.dialogIsVisible = false;
    },
    redirect(url) {
      this.$router.push(url);
    },
    openNav() {
      this.sidenavWidth = 250;
    },
    closeNav() {
      this.sidenavWidth = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.fix-height-top {
  position: relative;
  height: 100px;
}
.logo-small img {
  max-height: 30px;
}
.head-title {
  font-size: 15px;
  font-weight: normal;
  color: $white;

  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  display: block;

  margin: auto;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
.back-btn {
  font-size: 20px;
  color: #8ba9f7;
  z-index: 999;
}
.lang-btn {
  font-size: 20px;
  color: $white;
  z-index: 999;
}
.header-fixed-top {
  height: 54px;
  width: 100%;
  position: relative;
}
.with-logo {
  margin-right: auto !important;
  margin-left: auto !important;
}
.without-logo {
  margin-left: 0.5rem !important;
  margin-right: auto !important;
}
#mainNav {
  // background: #dd5e0a !important;
  // box-shadow: 0px 2px 5px #8c87cb;
  background: none !important;
  background-image: linear-gradient(
    180deg,
    map-get($theme-gradient-colors, 'secondary')
  ) !important;
  height: 54px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  width: 100%;
}
#mainNav .navbar-brand {
  color: $black-100;
  font-weight: 800;
}
#mainNav .navbar-brand img {
  max-height: 55px;
}
#mainNav .header-list li a {
  color: $black-100;
  text-decoration: none;
}
#mainNav .header-list li a svg {
  font-size: 1.3rem;
}
#mainNav .header-list li a img {
  width: 24px;
  // filter: invert(60%) sepia(85%) saturate(414%) hue-rotate(193deg)
  //   brightness(96%) contrast(101%);
}
#mainNav .header-list li a:hover,
#mainNav .header-list li a:active,
#mainNav .header-list li a:focus {
  // color: #cf4343;
}
.triangle-pointer {
  position: absolute;
  top: -10%;
  right: 35%;
  z-index: 1002;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid #8ba9f7;
}
.language-menu-wrapper {
  position: absolute;
  width: 150px;
  top: 125%;
  right: -200%;
  background: $black-400;
  transition: all 0.3s ease-in-out;
  display: none;
  box-shadow: 0px 3px 8px 2px rgba($black-100, 0.25);
  border-top: 3px solid #8ba9f7;
  color: $gray-300;
}
ul.language-list {
  list-style: none;
  text-align: center;
  width: 100%;
  z-index: 2;
  padding: 0.5rem 0;
  margin-bottom: 0;
}
ul.language-list li.language-list-item {
  padding: 0.5rem 1.5rem;
  font-size: 18px;
}
ul.language-list li.language-list-item:hover {
  color: #8ba9f7;
}
.language-btn:hover .language-menu-wrapper {
  display: block;
  cursor: pointer;
}

.notify_bell {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: $yellow-300;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: $black-100;
  font-weight: bold;
}

.sidenav {
  // height: 100%;
  height: calc(100% - 60px);
  width: 0;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: $black-100;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  box-shadow: 0 0 4px $white;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: $gray-500;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}
span.open_btn {
  color: $white;
  font-size: 20px;
  cursor: pointer;
  // z-index: 1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
</style>

<template>
  <div class="mobile">
    <title>{{ pageTitle }}</title>
    <nav
      class="navbar navbar-expand-lg navbar-light bg-light p-2"
      v-if="showNav"
      id="mainNav"
    >
      <a
        v-if="showReloadButton"
        class="back-btn ml-3"
        @click="this.$router.go()"
      >
        <img
          src="@/assets/images/svg2/reload.svg"
          alt=""
          class="img"
          style="width: 15px; height: 15px"
        />
      </a>
      <div class="logo-small" v-if="showLogo" @click="this.$router.push('/')">
        <img v-if="logo" :src="logo" alt="" class="img" />
      </div>
      <a
        v-if="showBackButton"
        class="back-btn text-white"
        @click="goPrevPage()"
        style="z-index: 1"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']" class="me-auto" />
      </a>

      <div :style="{ width: sidenavWidth + 'px' }" class="sidenav">
        <h5 class="text-white ms-3">{{ $t('index_speed_contract') }}</h5>
        <a class="closebtn" @click="closeNav">&times;</a>
        <!-- Market Component -->
        <Market @custom-event="parentFunction" :close-nav="closeNav" :showBurgerPage="showBurgerPage" />
      </div>
      <a v-if="showBurgerButton" class="text-white ms-2">
        <span class="open_btn" @click="openNav" v-if="burger_btn == '1'"
          >&#9776;</span
        >
      </a>
      <h3 class="head-title" :class="mode">{{ pageTitle }}</h3>
      <ul class="list-inline mb-0 header-list" v-if="showLangButton">
        <li class="list-inline-item me-1 me-sm-1" style="position: relative">
          <a class="lang-btn text-white" @click="redirect('/account/language')">
            <font-awesome-icon :icon="['fas', 'globe']" class="ms-auto" />
          </a>
        </li>
      </ul>
      <ul class="list-inline mb-0 header-list" v-if="showItem">
        <li class="list-inline-item me-2 me-sm-3" style="position: relative">
          <a class="">
            <img
              src="@/assets/images/svg2/messenger.svg"
              alt=""
              class="img"
              style="width: 20px; height: 20px"
              @click="redirect('/account/notification')"
            />
          </a>
          <div class="notify_bell" v-if="counter > 0">{{ counter }}</div>
        </li>
        <li class="list-inline-item me-1 me-sm-1 active position-relative">
          <a class="">
            <img
              src="@/assets/images/svg2/setting.svg"
              alt=""
              class="img"
              style="width: 20px; height: 20px"
              @click="redirect('/account/settings')"
            />
          </a>
        </li>
      </ul>
    </nav>
    <div class="header-fixed-top"></div>
    <slot></slot>

    <footer-theme3 v-if="showFooter"></footer-theme3>
  </div>
</template>
