<script>
export default {
	data() {

		return {
			lock: 0,
			prephonenumber: {
				val: '86',
				isValid: true,
			},
			phonenumber: {
				val: '',
				isValid: true,
			},
			realname: {
				val: '',
				isValid: true,
			},
			username: {
				val: '',
				isValid: true,
			},
			password: {
				val: '',
				isValid: true,
			},
			confirmpassword: {
				val: '',
				isValid: true,
			},
			secpassword: {
				val: '',
				isValid: true,
			},
			confirmsecpassword: {
				val: '',
				isValid: true,
			},
			invitecode: {
				val: '',
				isValid: true,
			},
			verifycode: {
				val: '',
				isValid: true,
			},
			agree: {
				val: ['terms'],
				isValid: true,
			},
			formIsValid: true,
			logo: '',
			code: '',
			canvas: '',
			env : process.env,
			isInvite : process.env.VUE_APP_REG_INVITECODE || 0,
		};
	},
	created() {
		let inviteCode = this.$route.query.i;
		// console.log( this.$route.query);
		if (inviteCode) {
			this.invitecode.val = inviteCode;
		}
	},
	beforeMount() {
		// this.init();
		this.updateMap();
	},
	mounted() {
		this.captcha = document.getElementById('captcha');
	},
	methods: {
		clearValidity(input) {
			this[input].isValid = true;
		},
		redirect(url) {
			this.$router.push(url);
		},
		validateForm() {
			this.formIsValid = true;
			// if (this.phonenumber.val === "") {
			// 	this.phonenumber.isValid = false;
			// 	this.formIsValid = false;
			// }
			if (this.username.val === '') {
				this.username.isValid = false;
				this.formIsValid = false;
			}

			if (process.env.VUE_APP_REG_EMAIL == '1') {
						const regex = /^([a-zA-Z0-9._-]+)@([a-zA-Z0-9._-]+\.[a-zA-Z]{2,5})$/;
						if (!regex.test(this.username.val)){
								this.username.isValid = false;
								this.formIsValid = false;
						}
			}

			if (process.env.VUE_APP_REG_NAME == '1') {
				if (this.realname.val === '') {
					this.realname.isValid = false;
					this.formIsValid = false;
				}
			}
			if (this.password.val === '') {
				this.password.isValid = false;
				this.formIsValid = false;
			}
			if (
				this.confirmpassword.val === '' ||
				this.confirmpassword.val !== this.password.val
			) {
				this.confirmpassword.isValid = false;
				this.formIsValid = false;
			}

			if (process.env.VUE_APP_REG_SEC == '1') {
				if (this.secpassword.val === '') {
					this.secpassword.isValid = false;
					this.formIsValid = false;
				}
				if (
					this.confirmsecpassword.val === '' ||
					this.confirmsecpassword.val !== this.secpassword.val
				) {
					this.confirmsecpassword.isValid = false;
					this.formIsValid = false;
				}
			}


			if(process.env.VUE_APP_REG_INVITECODE=='1'){
				if (this.invitecode.val === "" ) {
					this.invitecode.isValid = false;
					this.formIsValid = false;
				}
			}
			/*
			if (this.verifycode.val === "") {
				this.verifycode.isValid = false;
				this.formIsValid = false;
			}
										 *
			 */
			if (this.agree.val.length === 0) {
				this.agree.isValid = false;
				this.formIsValid = false;
			}
		},
		submitForm() {
			if (this.lock === 1) {
				return '';
			}
			this.lock = 1;
			this.validateForm();

			if (!this.formIsValid) {
				this.lock = 0;
				return;
			}

			if(process.env.VUE_APP_REG_INVITECODE=='1'){
				if (this.invitecode.val === "" ) {
					this.invitecode.isValid = false;
					this.formIsValid = false;
				}
			}

			var invite = process.env.VUE_APP_REG_INVITECODE=='1'?this.invitecode.val:0;

			let obj = {
				password: this.password.val,
				password_confirm: this.confirmpassword.val,
				username: this.username.val,
				realname: this.realname.val,
				secpassword: this.secpassword.val,
				secpassword_confirm: this.confirmsecpassword.val,
				parent_uid: invite, //this.invitecode.val,
				reg_from: window.location.host, //this.invitecode.val,
				email : process.env.VUE_APP_REG_EMAIL > 0 ? 1 : 0
			};

			this.$http('', '/home/auth/register.html', obj)
				.then((res) => {
					if (res.code === '0001') {
						this.$store.commit('setSession', {
							token: btoa(JSON.stringify(res.data)),
						});
						this.$toast.success((res.message));
						setTimeout(() => {
							this.$router.push('/account');
						}, parseInt(res.time) * 1000);
						this.lock = 1;
						//this.skipPage(res.url);
					} else {
						this.lock = 0;
						this.$toast.error(res.message);
					}
				})
				.catch((e) => {
					this.lock = 0;
					this.$toast.error(this.$t(e));
				});
		},
		updateMap() {
			this.$http('', '/home/index/config', [], 'get')
				.then((result) => {
					// console.log(result)
					if (result.code == '0001') {
						this.config = result.data.config.setting;
						var logo =
							process.env.VUE_APP_IMAGE_URL + '/uploads/' + this.config.logo;
						this.logo = logo;
						// console.log(logo)
					} else {
						// this.$toast.error(result.message);
					}
				})
				.catch((e) => {
					console.log(e);
					this.lock = 0;
					//this.$toast.error(e.message);
				});
		},
		init() {
			var params = {
				rmt: Math.floor(Date.now() / 1000),
			};
			this.$http('', 'home/auth/gvcode.html', params, 'post')
				.then((result) => {
					var canv = document.createElement('canvas');
					canv.id = 'captcha';
					canv.width = 100;
					canv.height = 50;
					var ctx = canv.getContext('2d');

					var str = result.data;

					ctx.fillStyle = '#696353';
					ctx.font = 'small-caps italic  25px Georgia';
					ctx.fillText(str, 8, 25);
					ctx.restore();
					this.code = str;
					this.canvas = canv;
					//storing captcha so that can validate you can save it somewhere else according to your specific requirements
					this.captcha.innerHTML = '';
					this.captcha.appendChild(this.canvas);
				})
				.catch((e) => {
					console.log(e);
					this.lock = 0;
					//this.$toast.error(e.message);
				});
		},
		recap() {
			var list = document.getElementById('captcha');
			list.removeChild(list.childNodes[0]);
			// this.init();
		},
	},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
.main-container {
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
}
label {
	display: block;
	text-align: left !important;
	/* margin-left: 10px; */
}

.login label.label {
	text-align: left;
	width: 100%;
	padding: 0 10px;
	color: $yellow-100;
	font-weight: bold;
}
.login ::placeholder {
	/* Most modern browsers support this now. */
	color: $gray-600 !important;
}
.login .card {
	border: none;
	border-radius: 10px;
	background-color: rgba($white, 0.1);
	color: $black-100;
	padding: 30px 0 0;
}
.login .card-body {
	flex: 1 1 auto;
	padding: 20px;
	color: $white;
	border-radius: 10px;
}
.login .form-control,
.form-select {
	border: 0 none;
	border-radius: 10px;
}
.login input.form-control {
	background-color: rgba($black-100, 0.3) !important;
	padding: 10px !important;
	margin: 5px 0;
	border-radius: 0px;
	background: transparent !important;
	color: $white;
	border-bottom: 1px solid $brown-300;
	height: 40px;
	line-height: 21px;
	width: 100%;
}
.login .terms a {
	color: $yellow-300;
	text-decoration: none;
}
.login .btn-login {
	background: $black-600;
	display: block;
	border: none;
	border-radius: 20px !important;
	padding: 10px 0;
	font-size: 16px;
	display: block;
	width: 100%;
	max-width: 192px;
	height: 52px;
	text-align: center;
	color: $yellow-100 !important;
	border-radius: 12px;
	border: 1px solid $brown-400 !important;
	margin: auto;
	background-image: none !important;
}
.invalid label {
	color: map-get($theme-colors, 'danger');
}

.invalid input,
.invalid textarea {
	border: 1px solid map-get($theme-colors, 'danger');
}
.error_message {
	color: map-get($theme-colors, 'danger');
	text-align: left;
	font-size: 12px;
	margin-bottom: 20px;
}
.register_middle {
	margin-top: 0%;
}
.c_img {
	background: url(~@/assets/images/captcha_bg.png);
	opacity: 0.8;
	width: 70px;
	height: 35px;
}
body {
	background: url(~@/assets/images/3/bg.png);
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
}
.login img.logo {
	width: auto !important;
	height: auto !important;
	max-width: 167px;
	height: 40px;
	display: block;
}
.login form .icon-lable {
	position: absolute;
	right: 10px;
	bottom: 10px;
	color: $black-100;
}
.terms {
	color: $yellow-300;
	text-decoration: none;
}
.login .invalid.mb-3,
.error_message {
	margin-bottom: 0 !important;
}
</style>
<!-- <style lang="less">
	@import '~@/assets/css/style3.css';
</style>-->
<template>
	<base-layout3
		:page-title="$t('reg_title')"
		:show-back-button="true"
		:show-footer="false"
		:show-item="false"
		:show-lang-button="true"
		:show-nav="true"
		mode=""
	>
		<main
			class="container-fluid h-100 flex-column flex-wrap main-container login pt-4 register_middle"
		>
			<div class="row h-100">
				<div class="col-11 mx-auto text-center">
					<img :src="logo" class="logo mx-auto mb-3" alt="logo" />
					<h2 class="text-center text-white">{{ $t('reg_title') }}</h2>

					<form @submit.prevent="submitForm">
						<div
								v-if ="env.VUE_APP_REG_NAME == '1'"
							class="mt-3 position-relative input-group"
							:class="{ invalid: !realname.isValid }"
						>
							<label class="label" for="realname">
								<font-awesome-icon
									:icon="['fas', 'user']"
									class="me-1 text-primary"
								/>
								{{ $t('reg_label_realname') }}
							</label>
							<input
								type="text"
								class="form-control"
								id="realname"
								:placeholder="$t('reg_realname')"
								v-model="realname.val"
								@blur="clearValidity('realname')"
							/>

							<p v-if="!realname.isValid" class="error_message">
								{{ $t('reg_realname_err') }}
							</p>
						</div>
						<div
							v-if ="env.VUE_APP_REG_EMAIL == '1'"
							class="mt-3 position-relative input-group"
							:class="{ invalid: !username.isValid }"
						>
							<label class="label" for="username">
								<font-awesome-icon
									:icon="['fas', 'user']"
									class="me-1 text-primary"
								/>
								{{$t('reg_label_email')}}</label>
							<input
								type="text"
								class="form-control"
								id="username"
								:placeholder="$t('reg_username')"
								v-model="username.val"
								@blur="clearValidity('username')"
							/>

							<p v-if="!username.isValid" class="error_message">
								{{ $t('reg_email_err') }}
							</p>
						</div>
						<div
							v-if ="env.VUE_APP_REG_EMAIL != '1'"
							class="mt-3 position-relative input-group"
							:class="{ invalid: !username.isValid }"
						>
							<label class="label" for="username">
								<font-awesome-icon
									:icon="['fas', 'user']"
									class="me-1 text-primary"
								/>
								{{$t('reg_label_username')}}</label>
							<input
								type="text"
								class="form-control"
								id="username"
								:placeholder="$t('reg_username')"
								v-model="username.val"
								@blur="clearValidity('username')"
							/>

							<p v-if="!username.isValid" class="error_message">
								{{ $t('reg_username_err') }}
							</p>
						</div>

						<div
							class="mt-3 position-relative input-group"
							:class="{ invalid: !password.isValid }"
						>
							<label class="label" for="password">
								<font-awesome-icon
									:icon="['fas', 'lock']"
									class="me-1 text-primary"
								/>
								{{$t('reg_label_pass')}}
							</label>
							<input
								type="password"
								class="form-control"
								id="password"
								:placeholder="$t('reg_pass')"
								v-model="password.val"
								@blur="clearValidity('password')"
							/>
							<p v-if="!password.isValid" class="error_message">
								{{ $t('login_pass_err') }}
							</p>
						</div>
						<div
							class="mt-3 position-relative input-group"
							:class="{ invalid: !confirmpassword.isValid }"
						>
							<label class="label" for="password">
								<font-awesome-icon
									:icon="['fas', 'lock']"
									class="me-1 text-primary"
								/>
								{{$t('reg_label_confirm_pass')}}
							</label>
							<input
								type="password"
								class="form-control"
								id="confirmpassword"
								:placeholder="$t('reg_repass')"
								v-model="confirmpassword.val"
								@blur="clearValidity('confirmpassword')"
							/>
							<p v-if="!confirmpassword.isValid" class="error_message">
								{{ $t('login_repass_err') }}
							</p>
						</div>
						<div
							v-if ="env.VUE_APP_REG_SEC == '1'"
							class="mt-3 position-relative input-group"
							:class="{ invalid: !secpassword.isValid }"
						>
							<label class="label" for="secpassword">
								<font-awesome-icon
									:icon="['fas', 'lock']"
									class="me-1 text-primary"
								/>
								{{$t('reg_label_sec_pass')}}
							</label>
							<input
								type="password"
								class="form-control"
								id="secpassword"
								:placeholder="$t('reg_secpass')"
								v-model="secpassword.val"
								@blur="clearValidity('secpassword')"
							/>
							<p v-if="!password.isValid" class="error_message">
								{{ $t('reg_secpass_err') }}
							</p>
						</div>
						<div
							v-if ="env.VUE_APP_REG_SEC == '1'"
							class="mt-3 position-relative input-group"
							:class="{ invalid: !confirmsecpassword.isValid }"
						>
							<label class="label" for="confirmsecpassword">
								<font-awesome-icon
									:icon="['fas', 'lock']"
									class="me-1 text-primary"
								/>
								{{$t('reg_label_confirm_sec_pass')}}
							</label>
							<input
								type="password"
								class="form-control"
								id="confirmsecpassword"
								:placeholder="$t('reg_re_secpass')"
								v-model="confirmsecpassword.val"
								@blur="clearValidity('confirmsecpassword')"
							/>
							<p v-if="!confirmsecpassword.isValid" class="error_message">
								{{ $t('reg_resecpass_err') }}
							</p>
						</div>
						<div class="mb-3" :class="{ invalid: !invitecode.isValid }" v-if="isInvite">
							<label class="label" for="invitecode">{{$t('reg_invite')}}</label>
							<input
								type="text"
								class="form-control"
								id="invitecode"
								:placeholder="$t('reg_invite')"
								v-model="invitecode.val"
								@blur="clearValidity('invitecode')"
							/>

							<label  style="position: absolute;right: 42px; margin-top: -45px;color:#757474" >
								<font-awesome-icon :icon="['fas', 'users']" class="me-auto"/>
							</label>

							<p v-if="!invitecode.isValid" class="error_message">
								{{ $t("login_parentid_err") }}
							</p>
						</div>
						<!-- <div class="mb-3" :class="{ invalid: !verifycode.isValid }">
							<label class="label" for="verifycode">{{$t('reg_label_captcha')}}</label>
							<input
								type="text"
								class="form-control"
								id="verifycode"
								:placeholder="$t('login_code')"
								v-model="verifycode.val"
								@blur="clearValidity('verifycode')"
							/>
							<label  style="position: absolute;right: 40px; margin-top: -52px;"  @click="recap()" >
								<div id="captcha" class="c_img"></div>
							</label>
							<p v-if="!verifycode.isValid" class="error_message">
								{{ $t("login_captcha_err") }}
							</p>
						</div> -->
						<div :class="{ invalid: !agree.isValid }">
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									id="terms"
									value="terms"
									v-model="agree.val"
									@blur="clearValidity('agree')"
								/>
								<label
									class="form-check-label terms mt-3"
									for="terms"
									v-html="$t('reg_term2')"
									@click="redirect('/register/terms')"
								>
								</label>
							</div>
							<p v-if="!agree.isValid" class="error_message">
								{{ $t('login_check_err') }}
							</p>
						</div>
						<div class="my-4">
							<button type="submit" class="btn btn-lg btn-login shadow-sm">
								{{ $t('reg_1') }}
							</button>
						</div>
					</form>
					<div class="text-center text-white mb-4">
						<a @click="redirect('/login')" class="btn text-white">{{
							$t('reg_login')
						}}</a>
					</div>
				</div>
			</div>
		</main>
	</base-layout3>
</template>
