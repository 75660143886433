<script>
import { getLocal } from '@/utils/local.js';
import { _ } from 'vue-underscore';
export default {
  name: 'login',
  components: {},
  data() {
    return {
      lock: 0,
      username: {
        val: '',
        isValid: true,
      },
      password: {
        val: '',
        isValid: true,
      },
      verifycode: {
        val: '',
        isValid: true,
      },
      formIsValid: true,
      logo: '',
      code: '',
      canvas: '',
      captcha: '',
      isiframe: false,
      issupport: false,
      service_link: '-',
    };
  },
  mounted() {
    this.captcha = document.getElementById('captcha');
  },
  beforeMount() {
    let token = getLocal('token') || '';
    //if(token)
    //this.$router.push('account');
    // console.log(token)
    // this.init();
    this.updateMap();
  },
  created() {},
  updated() {},
  unmounted() {},
  methods: {
    redirect(url) {
      this.$router.push(url);
    },
    clearValidity(input) {
      this[input].isValid = true;
    },
    validateForm() {
      this.formIsValid = true;
      if (this.username.val === '') {
        this.username.isValid = false;
        this.formIsValid = false;
      }
      if (this.password.val === '') {
        this.password.isValid = false;
        this.formIsValid = false;
      }
      // if (this.verifycode.val === "") {
      // 	this.verifycode.isValid = false;
      // 	this.formIsValid = false;
      // }
      // console.log(this.code,this.verifycode.val)
      // if(this.verifycode.val != this.code){
      // 	this.verifycode.isValid = false;
      // 	this.formIsValid = false;
      // }
    },
    submitForm() {
      if (this.lock === 1) {
        return '';
      }
      this.lock = 1;
      this.validateForm();

      if (!this.formIsValid) {
        this.lock = 0;
        return;
      }

      let obj = {
        username: this.username.val,
        password: this.password.val,
        // captcha: this.verifycode.val,
      };

      this.$http('', 'home/auth/login.html', obj)
        .then((res) => {
          if (res.code === '0001') {
            this.$store.commit('setSession', {
              token: btoa(JSON.stringify(res.data)),
            });
            this.$toast.success(res.message);
            setTimeout(
              () => this.$router.push('/account'),
              parseInt(res.time) * 1000
            );
            this.lock = 1;
            // this.skipPage(res.url);
          } else {
            // this.recap();
            this.$toast.error(res.message);
            this.lock = 0;
          }
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
          //this.$toast.error(e.message);
        });
    },
    updateMap() {
      this.$http('', '/home/index/config', [], 'get')
        .then((result) => {
          if (result.code == '0001') {
            this.config = result.data.config.setting;
            var logo =
              process.env.VUE_APP_IMAGE_URL + '/uploads/' + this.config.logo;
            this.logo = logo;
            // console.log(logo)
          } else {
            // this.$toast.error(result.message);
          }
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
          //this.$toast.error(e.message);
        });
    },
    init() {
      var params = {
        rmt: Math.floor(Date.now() / 1000),
      };
      this.$http('', 'home/auth/gvcode.html', params, 'post')
        .then((result) => {
          var canv = document.createElement('canvas');
          canv.id = 'captcha';
          canv.width = 100;
          canv.height = 50;
          var ctx = canv.getContext('2d');

          var str = result.data;

          ctx.fillStyle = '#696353';
          ctx.font = 'small-caps italic  25px Georgia';
          ctx.fillText(str, 8, 25);
          ctx.restore();
          this.code = str;
          this.canvas = canv;
          //storing captcha so that can validate you can save it somewhere else according to your specific requirements
          this.captcha.innerHTML = '';
          this.captcha.appendChild(this.canvas);
        })
        .catch((e) => {
          console.log(e);
          this.lock = 0;
          //this.$toast.error(e.message);
        });
    },
    recap() {
      var list = document.getElementById('captcha');
      list.removeChild(list.childNodes[0]);
      // this.init();
    },
    closeiframe() {
      this.isiframe = false;
      this.issupport = false;
      this.service_link = '-';
      document.querySelector('html').removeAttribute('style');
      document.querySelector('body').removeAttribute('style');
    },
    gosupport() {
      (this.service_link = getLocal('service_link') || '-'),
        (this.isiframe = true);
      this.issupport = true;
      document.querySelector('html').setAttribute('style', 'max-width: unset;');
      document.querySelector('body').setAttribute('style', 'max-width: unset;');
    },
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import '~@/assets/scss/_variables.scss';
body #app {
  overflow-y: auto;
}
.main-container {
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.login ::placeholder {
  /* Most modern browsers support this now. */
  color: $gray-600 !important;
}
.login .card {
  border: none;
  border-radius: 10px;
  background-color: rgba($white, 0.1);
  color: $black-100;
  padding: 30px 0 0;
}
.login .card-body {
  flex: 1 1 auto;
  padding: 20px;
  color: $white;
  border-radius: 10px;
}
.login .form-control,
.form-select {
  border: 0 none;
  border-radius: 10px;
}
.login input.form-control {
  background-color: rgba($black-100, 0.3) !important;
  padding: 10px !important;
  margin: 5px 0;
  border-radius: 0px;
  background: transparent !important;
  color: #333;
  border-bottom: 1px solid #ccc;
  line-height: 21px;
  width: 100%;
}
.login .terms a {
  color: $yellow-300;
  text-decoration: none;
}
.login .btn-login {
  background: #eab300;
  display: block;
  border: none;
  border-radius:0!important;
  padding: 10px 0;
  font-size: 16px;
  display: block;
  width: 100%;
  text-align: center;
  color: #fff!important;
  margin: auto;
  background-image: none !important;
}

.login .default-btn {
  background: #eab300;
  display: block;
  border: none;
  border-radius:0!important;
  padding: 10px 0;
  font-size: 16px;
  display: block;
  width: 100%;
  max-width:600px!important;
  text-align: center;
  color: #fff!important;
  margin: auto;
  background-image: none !important;
}

.login label.label {
  text-align: left;
  width: 100%;
  padding: 0 10px;
  color: $theme3-gray-500;
  font-weight: bold;
}
.invalid label {
  color: map-get($theme-colors, 'danger');
}

.invalid input,
.invalid textarea {
  border: 1px solid map-get($theme-colors, 'danger');
}
.error_message {
  color: map-get($theme-colors, 'danger');
  text-align: left;
  font-size: 12px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.middle {
  margin-top: 5%;
}
.c_img {
  background: url(~@/assets/images/captcha_bg.png);
  opacity: 0.8;
  width: 70px;
  height: 35px;
}
body {
  background: url(~@/assets/images/themes/theme3/bg.png) !important;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.login img.logo {
  width: auto !important;
  height: auto !important;
  max-width: 167px;
  height: 40px;
  display: block;
}
.login form .icon-lable {
  position: absolute;
  right: 10px;
  bottom: 10px;
  color: $black-100;
}
.register-text button {
  
  font-size: 14px !important;
  height: 40px !important;
}
.order-detail1 {
  background: $black-500;
  // height: 100%;
  height: 100vh; //calc(100vh - 64px);
  position: fixed;
  width: 100%;
  // top: 54px;
  top: 0;
  left: 0;
  z-index: 1000;

  a.close {
    position: fixed;
    color: $white;
    text-decoration: none;
    font-size: 16px;
    text-align: center;
    width: 100%;
    background: rgba($black-100, 0.376);
    bottom: 0;
    padding: 20px;
  }
}
.close2 {
  background: $blue;
  position: fixed;
  color: $white;
  width: 35px;
  height: 35px;
  top: 20px;
  left: 0px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
iframe {
  display: block; /* iframes are inline by default */
  border: none; /* Reset default border */
  height: calc(100vh - 54px); /* Viewport-relative units */
  width: 100%;
}
body {
  background: url(~@/assets/images/themes/theme3/bg.png) !important;
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.text-primary { color:$theme3-primary!important; }
</style>

<template>
  <base-layout3
    :page-title="$t('login_btn')"
    :show-nav="true"
    :show-back-button="true"
    :show-footer="false"
    :show-item="false"
    :page-default-back-link="'/'"
    :show-lang-button="true"
    mode=""
  >
    <main
      class="container-fluid h-100 main-container login justify-content-center align-content-center middle"
    >
      <div
        class="row h-100 flex-column flex-wrap justify-content-center align-content-center"
      >
        <img :src="logo" class="logo mb-3 mx-auto" alt="logo" />
        <div class="col-11 mx-auto text-center">
          <div class="mb-2">
            <h2 class="text-center">{{ $t('login_btn') }}</h2>
            <form @submit.prevent="submitForm">
              <div
                class="mt-3 position-relative input-group"
                :class="{ invalid: !username.isValid }"
              >
                <label class="label" for="username">
                  <font-awesome-icon
                    :icon="['fas', 'user']"
                    class="me-2 text-primary"
                  />{{$t('login_label_username')}}
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  :placeholder="$t('login_username')"
                  v-model.trim="username.val"
                  @blur="clearValidity('username')"
                />
                <p v-if="!username.isValid" class="error_message">
                  {{ $t('login_username_err') }}
                </p>
              </div>
              <div class="mb-1 mt-3 position-relative input-group" :class="{ invalid: !password.isValid }">
                <label class="label" for="password">
                  <font-awesome-icon
                    :icon="['fas', 'lock']"
                    class="me-2 text-primary"
                  />
                  {{$t('login_label_pass')}}
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  :placeholder="$t('reg_pass')"
                  v-model="password.val"
                  @blur="clearValidity('password')"
                />

                <p v-if="!password.isValid" class="error_message">
                  {{ $t('login_pass_err') }}
                </p>
              </div>
              <!-- <div class="mb-1" :class="{ invalid: !verifycode.isValid }">
									<label class="label" for="verifycode">{{$t('login_label_captcha')}}</label>
									<input
										type="text"
										class="form-control"
										id="verifycode"
										:placeholder="$t('login_code')"
										v-model="verifycode.val"
										@blur="clearValidity('verifycode')"
									/>
									<label  style="position: absolute;right: 42px; margin-top: -52px;"  @click="recap()" >
										<div id="captcha" class="c_img" ></div>

									</label>
									<p v-if="!verifycode.isValid" class="error_message">
										{{ $t("login_captcha_err") }}
									</p>
								</div> -->
              <div class="my-4">
                <button type="submit" class="btn btn-lg btn-login">
                  {{ $t('login_btn') }}
                </button>
              </div>
            </form>
          </div>
          <div class="text-center text-white register-text">
            <!-- {{$t('login_no_acc')}}<a @click="redirect('/register')" class="" style="color: #FE8C00;text-decoration: none;">{{$t("index_register")}}</a> -->
            <base-button mode="default-btn" @click="redirect('/register')">{{
              $t('login_register')
            }}</base-button>
            <base-button
              mode="default-btn"
              type="button"
              @click="gosupport()"
              class="mt-3"
              >{{ $t('login_livechat') }}</base-button
            >
          </div>
        </div>
      </div>
    </main>

    <div class="order-detail1" :class="!isiframe ? 'd-none' : ''">
      <iframe :src="service_link" class="" loading="lazy"></iframe>
      <a v-if="isiframe" @click="this.closeiframe()" class="close2"> 《 </a>
    </div>
  </base-layout3>
</template>
